import React, { FormEvent, useMemo } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { updateShadowingCityFilter } from "../actions";
import { RootState } from "../../../app/store/configureStore";
import useItemFilter from "../../../app/hooks/useItemFilter";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface IProps {
  cities: string[];
}

const IndustryFilter = ({ cities }: IProps) => {
  const dispatch = useAppDispatch();
  const handleChange = (e: FormEvent<HTMLInputElement>, { value }: any) => {
    dispatch(updateShadowingCityFilter(value as string));
  };
  const { t } = useTranslation();
  const cityObjects = useMemo(() => cities.map((city) => ({ name: city })), [cities]);
  const { filteredItems: filteredCities, renderFilter } = useItemFilter(cityObjects);

  const selectedCities = useSelector((state: RootState) => state.shadowingsFilter.cities);

  return (
    <>
      {renderFilter()}
      <Form className="shadowing-filter__checkbox-group">
        {filteredCities.length === 0 ? (
          <span>{t("common.no_records")}</span>
        ) : (
          filteredCities.map((city) => (
            <Form.Field key={city.name}>
              <Checkbox
                onChange={handleChange}
                key={city.name}
                value={city.name}
                checked={selectedCities.includes(city.name)}
                className="sh-filter"
                label={<label>{city.name}</label>}
              />
            </Form.Field>
          ))
        )}
      </Form>
    </>
  );
};

export default IndustryFilter;
