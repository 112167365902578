import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from "semantic-ui-react";
import NavBar from "../../features/nav/NavBar";
import CompanyDashboard from "../../features/companies/components/CompanyDashboard";
import { userActions } from "../../features/user/actions";
import SchoolDashboard from "../../features/schools/SchoolDashboard";
import MunicipalityDashboard from "../../features/municipalities/components/MunicipalityDashboard";
import MunicipalityForm from "../../features/municipalities/components/MunicipalityForm";
import StudentDashboard from "../../features/students/components/StudentDashboard";
import MyCompetencies from "../../features/students/components/MyCompetencies";
import Profile from "../../features/profile/components/Profile";
import AuthRoute from "../auth/AuthRoute";
import { AuthRoutes, NonAuthRoutes } from "../auth/routes";
import { UserRoles } from "../auth/roles";
import { AnyRole } from "../auth/roles";
import Footer from "./footer/Footer";
import StudentApplicationsPage from "../../features/applications/components/StudentApplicationsPage";
import StudentGuidePage from "../../features/guide/StudentGuidePage";
import GuideMunicipalityPage from "../../features/guide/GuideMunicipalityPage";
import GuideSchoolPage from "../../features/guide/GuideSchoolPage";
import LoginPage from "../../features/user/components/login/LoginPage";
import RegistrationPage from "../../features/user/components/RegistrationPage";
import PasswordRecoveryPage from "../../features/user/components/passwordRecovery/PasswordRecoveryPage";
import PasswordRecoveryConfirmationPage from "../../features/user/components/passwordRecovery/PasswordRecoveryConfirmationPage";
import CompanyPage from "../../features/companies/components/CompanyPage";
import MyPositionsPage from "../../features/companies/components/MyPositionsPage";
import HomePage from "../../features/home-page/HomePage";
import ShadowingsHome from "../../features/shadowings-home/ShadowingsHome";
import ReportsPage from "../../features/reports/ReportsPage";
import SeasonsAdminPage from "../../features/seasons/SeasonsAdminPage";
import PrivacyPolicyPage from "../../features/privacy/PrivacyPolicyPage";
import FillReflections from "../../features/reflections/FillReflections";
import OpenFeedback from "../../features/companies/components/OpenFeedback";
import UsersDashboard from "../../features/users/UsersDashboard";
import RegistrationModal from "../../components/registration-modal/RegistrationModal";
import StudentProfileForceUpdateModal from "../../features/profile/components/student/StudentProfileForceUpdateModal";
import { useAppDispatch } from "../hooks/useAppDispatch";
import PartnersPage from "../../features/partners/PartnersPage";
import ExcursionPage from "../../features/excursions/ExcursionPage";
import MyExcursionPage from "../../features/excursions/MyExcursionPage";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userActions.getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar />
      <Container style={{ paddingTop: 100 }}>
        <Routes>
          <Route path="/" element={<Navigate to={NonAuthRoutes.about} replace />} />
          <Route path={NonAuthRoutes.fillReflections} element={<FillReflections />} />
          <Route path={NonAuthRoutes.openFeedback} element={<OpenFeedback />} />
          <Route path={NonAuthRoutes.about} element={<HomePage />} />
          <Route path={NonAuthRoutes.shadowings} element={<ShadowingsHome />} />
          <Route path={NonAuthRoutes.login} element={<LoginPage />} />
          <Route path={NonAuthRoutes.registration} element={<RegistrationPage />} />
          <Route path={NonAuthRoutes.passwordRecovery} element={<PasswordRecoveryPage />} />
          <Route path={NonAuthRoutes.passwordRecoveryConfirmation} element={<PasswordRecoveryConfirmationPage />} />
          <Route path={NonAuthRoutes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={NonAuthRoutes.partners} element={<PartnersPage />} />
          <Route path={NonAuthRoutes.excursions} element={<ExcursionPage />} />

          <Route
            path={AuthRoutes.companies}
            element={
              <AuthRoute requiredRoles={[UserRoles.municipality, UserRoles.admin]}>
                <CompanyDashboard />
              </AuthRoute>
            }
          />
          <Route path={NonAuthRoutes.companyDetails} element={<CompanyPage />} />

          <Route
            path={AuthRoutes.myPositions}
            element={
              <AuthRoute requiredRoles={[UserRoles.company]}>
                <MyPositionsPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.myExcursions}
            element={
              <AuthRoute requiredRoles={[UserRoles.company]}>
                <MyExcursionPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.myCompetencies}
            element={
              <AuthRoute requiredRoles={[UserRoles.student]}>
                <MyCompetencies />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.municipalities}
            element={
              <AuthRoute requiredRoles={[UserRoles.admin]}>
                <MunicipalityDashboard />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.createMunicipality}
            element={
              <AuthRoute requiredRoles={[UserRoles.admin]}>
                <MunicipalityForm />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.seasons}
            element={
              <AuthRoute requiredRoles={[UserRoles.admin]}>
                <SeasonsAdminPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.schools}
            element={
              <AuthRoute requiredRoles={[UserRoles.admin, UserRoles.municipality]}>
                <SchoolDashboard />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.users}
            element={
              <AuthRoute requiredRoles={[UserRoles.admin]}>
                <UsersDashboard />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.students}
            element={
              <AuthRoute requiredRoles={[UserRoles.school]}>
                <StudentDashboard />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.guide}
            element={
              <AuthRoute requiredRoles={[UserRoles.student]}>
                <StudentGuidePage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.municipalityContent}
            element={
              <AuthRoute requiredRoles={[UserRoles.municipality]}>
                <GuideMunicipalityPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.schoolContent}
            element={
              <AuthRoute requiredRoles={[UserRoles.school]}>
                <GuideSchoolPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.applications}
            element={
              <AuthRoute requiredRoles={[UserRoles.student, UserRoles.school]}>
                <StudentApplicationsPage />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.profile}
            element={
              <AuthRoute requiredRoles={AnyRole}>
                <Profile />
              </AuthRoute>
            }
          />

          <Route
            path={AuthRoutes.reports}
            element={
              <AuthRoute requiredRoles={[UserRoles.company, UserRoles.school, UserRoles.municipality, UserRoles.admin]}>
                <ReportsPage />
              </AuthRoute>
            }
          />

          <Route path="*" element={<Navigate to={NonAuthRoutes.about} replace />} />
        </Routes>
      </Container>
      <Footer />
      <StudentProfileForceUpdateModal />
      <RegistrationModal />
    </>
  );
}

export default App;
