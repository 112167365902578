import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Company } from "../types";
import agent from "../../../app/api/agent";
import PageTitle from "../../../components/page-title/PageTitle";
import { Item, Grid, Table } from "semantic-ui-react";
import ShadowingItem from "../../shadowings/components/ShadowingItem";
import { useSelector } from "react-redux";
import { fetchCompanyShadowings } from "../../shadowings/actions";
import { RootState } from "../../../app/store/configureStore";
import ScaledImage from "../../../components/ScaledImage";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const CompanyDetails = () => {
  const { t } = useTranslation();
  const [company, setCompany] = useState<Company>();
  const { id } = useParams();
  const companyId = useMemo(() => Number(id), [id]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shadowings = useSelector((state: RootState) => state.shadowings.shadowings);

  useEffect(() => {
    agent.Companies.details(companyId)
      .then((response) => {
        setCompany(response);
      })
      .catch(() => {
        navigate("/");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchCompanyShadowings(companyId));
  }, [dispatch, companyId]);

  return (
    <Fragment>
      {!company ? null : (
        <Fragment>
          <div className="company-details-bg">
            <img src="/assets/company_inner/bg.jpeg" alt="primary" />
          </div>
          <div className="company-details-logo">
            <ScaledImage url={company.logoUrl || "/assets/logo_placeholder.png"} width={190} height={60} />
          </div>

          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={10}>
                <PageTitle title={company.name} description={company.description} />
              </Grid.Column>
              <Grid.Column width={6} style={{ marginTop: 55 }}>
                <Table basic="very" className="company-details">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <h4>{t("common.address")}</h4>
                      </Table.Cell>
                      <Table.Cell>{company.address}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <h4>{t("common.industry")}</h4>
                      </Table.Cell>
                      <Table.Cell>{company.industry.name}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="company-details-positions-count">
            <h4>
              {t("companies.details_page.company_positions")} <span>({shadowings.length})</span>
            </h4>
          </div>

          <Item.Group>
            {shadowings.length > 0 &&
              shadowings.map((shadowing) => (
                <ShadowingItem key={shadowing.id} shadowing={shadowing} showMoreCompanyPositions={false} hideLogo />
              ))}
          </Item.Group>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CompanyDetails;
