export enum AuthRoutes {
  profile = "/profile",
  companies = "/companies",
  municipalities = "/municipalities",
  createMunicipality = "/municipalities/new",
  students = "/students",
  schools = "/schools",
  guide = "/guide",
  applications = "/applications",
  statistics = "/statistics",
  myPositions = "/myPositions",
  myExcursions = "/myExcursions",
  myCompetencies = "/myCompetencies",
  municipalityContent = "/municipalityContent",
  schoolContent = "/schoolContent",
  reports = "/reports",
  seasons = "/seasons",
  users = "/users",
}

export enum NonAuthRoutes {
  home = "/",
  about = "/about",
  login = "/login",
  passwordRecovery = "/passwordRecovery",
  passwordRecoveryConfirmation = "/reset",
  registration = "/registration",
  shadowings = "/shadowings",
  companyDetails = "/companies/:id",
  privacyPolicy = "/privacy",
  fillReflections = "/fill-reflections/:applicationId",
  openFeedback = "/open-feedback/:shadowingId",
  partners = "/partners",
  excursions = "/excursions",
}
