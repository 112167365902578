import { useMemo } from "react";

const grades = [9, 10, 11, 12];

const useGrades = () => {
  return useMemo(
    () => ({
      grades,
      gradeOptions: grades.map((grade) => ({ key: grade, value: grade, text: grade })),
    }),
    []
  );
};

export default useGrades;
