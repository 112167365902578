import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import useIsLoggedIn from "../../app/hooks/useIsLoggedIn";
import { NonAuthRoutes } from "../../app/auth/routes";
import "./styles.less";
import { useTranslation } from "react-i18next";

const STORAGE = sessionStorage;
const STORAGE_KEY = "registrationModalClosed";

const RegistrationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const wasClosed = STORAGE.getItem(STORAGE_KEY) === "true";
  const [isOpen, setOpen] = useState(true);
  const renderModal = !isLoggedIn && !wasClosed && isOpen;

  if (!renderModal) return null;

  const close = () => {
    STORAGE.setItem(STORAGE_KEY, "true");
    setOpen(false);
  };

  const handleRegistrationClick = () => {
    close();
    navigate(NonAuthRoutes.registration);
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={null}
      open={true}
      closeIcon={true}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      size="fullscreen"
      onClose={() => close()}
    >
      <div className="registration-modal">
        <img src="/assets/seseliavimo-savaite.jpeg" alt="" className="registration-modal__image" />

        <div className="registration-modal__content">
          <p>
            Pasinaudokite išankstinės registracijos galimybe ir jau dabar pradėkite ruoštis savo ateities karjerai! Jau
            visai netrukus visus Lietuvos mokinius kviesime įgyti šešėliavimo patirties pasirinktoje organizacijoje.
          </p>
          <p>
            LJA Šešėliavimo programa padeda ruoštis savo ateities karjerai, nes:
            <ul>
              <li>Padeda geriau pažinti save;</li>
              <li>Suteikia galimybę susipažinti su skirtingomis profesijomis;</li>
              <li>Padeda sėkmingai siekti savo profesinių tikslų.</li>
            </ul>
          </p>
          <p>Kviečiame registruotis ir kelti šešėliavimo bangą kartu!</p>
          <div className="registration-modal__actions">
            <Button primary basic onClick={() => handleRegistrationClick()}>
              {t("auth.registration")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
