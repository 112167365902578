import React from "react";
import "./styles.less";
import PartnersMainArticle from "./PartnersMainArticle";
import PartnersArticle1 from "./PartnersArticle1";
import PartnersArticle2 from "./PartnersArticle2";

const PartnersPage = () => {
  return (
    <section className="partners-page">
      <PartnersMainArticle />
      <PartnersArticle1 />
      <PartnersArticle2 />
    </section>
  );
};

export default PartnersPage;
