import { useEffect, useMemo, useState } from "react";

export type City = string;

const useHardcodedCities = () => {
  const [cities, setCities] = useState<City[]>([]);

  const hardcodedCities = [
    "Akmenės r.",
    "Alytaus m.",
    "Alytaus r.",
    "Anykščių r.",
    "Birštono",
    "Biržų r.",
    "Druskininkų",
    "Elektrėnų",
    "Ignalinos r.",
    "Jonavos r.",
    "Joniškio r.",
    "Jurbarko r.",
    "Kaišiadorių r.",
    "Kalvarijos",
    "Kauno m.",
    "Kauno r.",
    "Kazlų Rūdos",
    "Kėdainių r.",
    "Kelmės r.",
    "Klaipėdos m.",
    "Klaipėdos r.",
    "Kretingos r.",
    "Kupiškio rajono savivaldybė",
    "Lazdijų rajono savivaldybė",
    "Marijampolės savivaldybė",
    "Mažeikių rajono savivaldybė",
    "Molėtų rajono savivaldybė",
    "Neringos savivaldybė",
    "Pagėgių savivaldybė",
    "Pakruojo rajono savivaldybė",
    "Palangos miesto savivaldybė",
    "Panevėžio miesto savivaldybė",
    "Panevėžio rajono savivaldybė",
    "Pasvalio rajono savivaldybė",
    "Plungės rajono savivaldybė",
    "Prienų rajono savivaldybė",
    "Radviliškio rajono savivaldybė",
    "Raseinių rajono savivaldybė",
    "Rietavo savivaldybė",
    "Rokiškio rajono savivaldybė",
    "Skuodo rajono savivaldybė",
    "Šakių rajono savivaldybė",
    "Šalčininkų rajono savivaldybė",
    "Šiaulių miesto savivaldybė",
    "Šiaulių rajono savivaldybė",
    "Šilalės rajono savivaldybė",
    "Šilutės rajono savivaldybė",
    "Širvintų rajono savivaldybė",
    "Švenčionių rajono savivaldybė",
    "Tauragės rajono savivaldybė",
    "Telšių rajono savivaldybė",
    "Trakų rajono savivaldybė",
    "Ukmergės rajono savivaldybė",
    "Utenos rajono savivaldybė",
    "Varėnos rajono savivaldybė",
    "Vilkaviškio rajono savivaldybė",
    "Vilniaus miesto savivaldybė",
    "Vilniaus rajono savivaldybė",
    "Visagino savivaldybė",
    "Zarasų rajono savivaldybė",
  ];

  useEffect(() => {
    setCities(hardcodedCities);
  }, []);

  return useMemo(
    () => ({
      cities,
      cityOptions: cities.map((city) => ({ key: city, value: city, text: city })),
    }),
    [cities]
  );
};

export default useHardcodedCities;
