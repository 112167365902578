import { Dispatch } from "redux";
import agent from "../../app/api/agent";
import {
  UserActionTypes,
  GET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_FAIL,
  UPDATE_USER,
  UserState,
} from "../../app/store/types/user";
import { UserForm } from "./types";
import { localStorageHelpers } from "../../app/helpers/localStorageHelpers";
import { UserRoles } from "../../app/auth/roles";
import { login } from "./login";

const getCurrentUser = () => async (dispatch: Dispatch<UserActionTypes>) => {
  if (!localStorageHelpers.getUserToken()) return;

  await agent.Users.current()
    .then((response) => {
      if (response.roles.includes(UserRoles.student)) {
        return Promise.all([agent.Students.getProfile(), agent.ApplicationReflections.getInitial()]).then(
          ([profile, reflections]) => ({
            ...response,
            seasonInfo: profile.seasonInfo,
            needToUpdateProfile: profile.needToUpdateProfile,
            needToFillReflection: Object.keys(reflections).length === 0,
          })
        );
      }

      return response;
    })
    .then((response) => {
      dispatch({
        type: GET_CURRENT_USER,
        user: { ...response, isLoggedIn: true },
      });

      localStorageHelpers.setUserInfo(response.id, response.token, true, response.roles, response.subjectId);
    })
    .catch((error) => {
      localStorageHelpers.clearStorage();

      dispatch({
        type: LOGIN_FAIL,
        status: error.response?.status,
      });

      window.location.href = "/";
    });
};

const loginUser = (user: UserForm, returnUrl: string) => async (dispatch: Dispatch<UserActionTypes>) => {
  return await agent.Users.login(user)
    .then((response) => {
      dispatch({
        type: LOGIN_USER,
        user: { ...response, isLoggedIn: true },
      });
      login(response, returnUrl);

      return { success: true };
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAIL,
        status: error.response?.status,
      });

      return { success: false, error };
    });
};

const logoutUser = () => async (dispatch: Dispatch<UserActionTypes>) => {
  await agent.Users.logout().then(() => {
    localStorageHelpers.clearStorage();

    dispatch({
      type: LOGOUT_USER,
    });

    window.location.href = "/";
  });
};

const updateUser = (payload: Partial<UserState>) => async (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({
    type: UPDATE_USER,
    payload,
  });
};

export const userActions = {
  loginUser,
  getCurrentUser,
  logoutUser,
  updateUser,
};
