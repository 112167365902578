import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Item, Tab } from "semantic-ui-react";
import PageTitle from "../../../components/page-title/PageTitle";
import ShadowingItem from "../../shadowings/components/ShadowingItem";
import { CompanyPositionsFilter } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import { fetchMyShadowings } from "../../shadowings/actions";
import useToaster from "../../../app/hooks/useToaster";
import Loader from "../../../components/Loader";
import useEventBus from "../../../app/hooks/useEventBus";
import ShadowingCreateModal from "../../shadowing/ShadowingCreateModal";
import { CompanyFeedbackButton } from "./CompanyFeedbackButton";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const MyPositionsPage = ({ showFeedback }: { showFeedback?: number }) => {
  const { t } = useTranslation();
  const { apiError } = useToaster();
  const { on } = useEventBus();

  const dispatch = useAppDispatch();
  const { isLoading, shadowings } = useSelector((state: RootState) => state.shadowings);

  const [activeFilter, setActiveFilter] = useState<CompanyPositionsFilter>({ past: !!showFeedback });
  const handleTabChange = (_: any, { activeIndex }: any) => {
    setActiveFilter({ past: activeIndex === 1 });
  };

  useEffect(() => {
    const load = async () => {
      try {
        await dispatch(fetchMyShadowings(activeFilter));
      } catch (error) {
        apiError(error);
      }
    };

    load();

    const unsubscribeCreated = on("shadowingCreated", load);
    const unsubscribeUpdated = on("shadowingUpdated", load);
    const unsubscribeCanceled = on("shadowingCanceled", load);

    return () => {
      unsubscribeCreated();
      unsubscribeUpdated();
      unsubscribeCanceled();
    };
  }, [activeFilter, apiError, dispatch, on]);

  const panes = [
    {
      menuItem: t("my_positions.tabs.upcoming.tab_title"),
      render: () => (
        <PageTitle
          title={t("my_positions.tabs.upcoming.title") + ` (${shadowings.length})`}
          description={t("my_positions.tabs.upcoming.description")}
        >
          <ShadowingCreateModal>
            <Button primary>{t("common.actions.create_position")}</Button>
          </ShadowingCreateModal>
        </PageTitle>
      ),
    },
    {
      menuItem: t("my_positions.tabs.past.tab_title"),
      render: () => (
        <PageTitle
          title={t("my_positions.tabs.past.title") + ` (${shadowings.length})`}
          description={t("my_positions.tabs.past.description")}
        >
          <CompanyFeedbackButton />
        </PageTitle>
      ),
    },
  ];

  return (
    <Fragment>
      <Tab
        activeIndex={activeFilter.past ? 1 : 0}
        onTabChange={handleTabChange}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Item.Group>
          {shadowings.length > 0 &&
            shadowings.map((shadowing) => (
              <ShadowingItem
                key={shadowing.id}
                shadowing={shadowing}
                showMoreCompanyPositions={false}
                openFeedbackModal={showFeedback === shadowing.id}
                hideLogo
              />
            ))}
        </Item.Group>
      )}
    </Fragment>
  );
};

export default MyPositionsPage;
