import React from "react";
import { Grid } from "semantic-ui-react";
import AppForm from "../../../components/form/Form";
import { BusinessRegistrationModel, BusinessRegistrationContactInfoStepFormModel } from "../types";
import BusinessRegistrationActions from "../BusinessRegistrationActions";
import { FULLNAME_REGEXP } from "../../../app/helpers/regexp";
import { useTranslation } from "react-i18next";
import { useAppForm } from "../../../components/form/useForm";

interface Props {
  model: Partial<BusinessRegistrationModel>;
  onSubmit: (m: BusinessRegistrationContactInfoStepFormModel) => any;
}

const BusinessRegistrationContactInfoStep: React.FC<Props> = ({ model, onSubmit }) => {
  const { t } = useTranslation();
  const formHook = useAppForm({ defaultValues: { ...defaultValues, ...model } });

  return (
    <>
      <p>{t("auth.steps.business.contactInfo.text")}</p>
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="contactInfo.fullName" label="auth.steps.business.contactInfo.fullName">
                <AppForm.Input
                  name="contactInfo.fullName"
                  placeholder="auth.steps.business.contactInfo.fullName_placeholder"
                  rules={{
                    required: true,
                    pattern: {
                      value: FULLNAME_REGEXP,
                      message: "shadowing.errors.fullname",
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="contactInfo.jobTitle" label="auth.steps.business.contactInfo.jobTitle">
                <AppForm.Input
                  name="contactInfo.jobTitle"
                  placeholder="auth.steps.business.contactInfo.jobTitle_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <AppForm.Group name="contactInfo.email" label="auth.steps.business.contactInfo.email">
                <AppForm.Email
                  name="contactInfo.email"
                  placeholder="auth.steps.business.email_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <AppForm.Group name="contactInfo.phoneNumber" label="auth.steps.business.contactInfo.phone">
                <AppForm.Phone name="contactInfo.phoneNumber" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>
      <BusinessRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: BusinessRegistrationContactInfoStepFormModel = {
  contactInfo: {
    fullName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
  },
};

export default BusinessRegistrationContactInfoStep;
