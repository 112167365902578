import React, { Fragment, PropsWithChildren } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { SiteUser } from "./types";
import AppForm from "../../components/form/Form";
import { useAppForm } from "../../components/form/useForm";

export interface UserEditForm {
  email: string;
}

interface Props {
  isSaving: boolean;
  user: SiteUser;
  handleSubmit: (data: UserEditForm) => void;
}

const UserUpdateForm = ({ isSaving, user, handleSubmit, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const formHook = useAppForm<UserEditForm>({
    defaultValues: { email: user.email },
  });

  return (
    <Fragment>
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <AppForm.Group name="email" label="common.email">
          <AppForm.Email name="email" rules={{ required: true }} />
        </AppForm.Group>
      </AppForm>

      <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("common.actions.confirm")}
      </Button>
      {children}
    </Fragment>
  );
};

export default UserUpdateForm;
