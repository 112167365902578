import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";
import { Shadowing } from "../shadowings/types";
import { DropdownItemProps, Form, Loader, Select } from "semantic-ui-react";

interface Props {
  isDisabled?: boolean;
  onChange: (value: Shadowing) => void;
}

const DuplicateShadowingDropdown = ({ isDisabled, onChange }: Props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Array<DropdownItemProps & { shadowing: Shadowing }> | undefined>(undefined);

  useEffect(() => {
    agent.Shadowings.listMyShadowings().then((shadowings) => {
      setOptions(
        shadowings.map((shadowing) => ({
          key: shadowing.id,
          value: shadowing.id,
          text: shadowing.position,
          shadowing,
        }))
      );
    });
  }, []);

  const isLoading = options == null;

  const handleChange = (id: number) => {
    const shadowing = options?.find((option) => option.value === id)?.shadowing;
    onChange(shadowing!);
  };

  return (
    <Form.Field>
      <Select
        icon={isLoading ? <Loader active /> : undefined}
        disabled={isDisabled}
        options={options ?? []}
        search
        value={""}
        placeholder={t("shadowing.copy_data")}
        onChange={(_, data) => handleChange(data.value as number)}
        selectOnBlur={false}
      />
    </Form.Field>
  );
};

export default DuplicateShadowingDropdown;
