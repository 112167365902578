import React from "react";
import { Grid } from "semantic-ui-react";
import AppForm from "../../../components/form/Form";
import { BusinessRegistrationCompanyContactsStepFormModel, BusinessRegistrationModel } from "../types";
import BusinessRegistrationActions from "../BusinessRegistrationActions";
import { useAppForm } from "../../../components/form/useForm";
import useHardcodedCities from "../../../app/hooks/useHardcodedCities";

interface Props {
  model: Partial<BusinessRegistrationModel>;
  onSubmit: (m: BusinessRegistrationCompanyContactsStepFormModel) => any;
}

const BusinessRegistrationCompanyContactsStep: React.FC<Props> = ({ model, onSubmit }) => {
  const formHook = useAppForm({ defaultValues: { ...defaultValues, ...model } });
  const { cityOptions } = useHardcodedCities();

  return (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="city" label="auth.steps.business.city">
                <AppForm.Select name="city" options={cityOptions} rules={{ required: true }} disableAutoSelect />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <AppForm.Group name="address" label="auth.steps.business.address">
                <AppForm.Input
                  name="address"
                  placeholder="auth.steps.business.address_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={4}>
              <AppForm.Group name="postcode" label="auth.steps.business.postcode">
                <AppForm.Input
                  name="postcode"
                  placeholder="auth.steps.business.postcode_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <AppForm.Group name="email" label="auth.steps.business.email">
                <AppForm.Email
                  name="email"
                  placeholder="auth.steps.business.email_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <AppForm.Group name="phoneNumber" label="auth.steps.business.phone">
                <AppForm.Phone name="phoneNumber" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>
      <BusinessRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: BusinessRegistrationCompanyContactsStepFormModel = {
  city: null!,
  postcode: "",
  address: "",
  email: "",
  phoneNumber: "",
};

export default BusinessRegistrationCompanyContactsStep;
