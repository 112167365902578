import { useMemo, useState } from "react";
import {
  ListFilterConfig,
  ListFilterConfigItemMultiSelectOption,
  ListFilterConfigItemType,
} from "../../components/list-filter/models";
import useExcursionCities from "../../app/hooks/useExcursionCities";
import useCompanies from "../../app/hooks/useCompanies";
import { useTranslation } from "react-i18next";
import useListFilter from "../../components/list-filter/useListFilter";

export interface ExcursionFilter {
  cities: ListFilterConfigItemMultiSelectOption[];
  online: ListFilterConfigItemMultiSelectOption[];
  companies: ListFilterConfigItemMultiSelectOption[];
  search: string;
  date: { start: Date; end: Date } | null;
}

const useExcursionFilter = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<ExcursionFilter>({
    cities: [],
    search: "",
    online: [],
    companies: [],
    date: null,
  });

  const { cities } = useExcursionCities();
  const { companyOptions } = useCompanies();

  const config: ListFilterConfig = useMemo(
    () => [
      {
        name: "search",
        title: "excursion.filter.search",
        type: ListFilterConfigItemType.Search,
      },
      {
        name: "cities",
        title: "excursion.filter.cities",
        type: ListFilterConfigItemType.MultiSelect,
        options: cities.map((city) => ({ key: city, value: city, text: city })),
      },
      {
        name: "online",
        title: "excursion.filter.online",
        type: ListFilterConfigItemType.MultiSelect,
        options: [
          {
            key: "online",
            value: "true",
            text: t("excursion.filter.only_online"),
          },
        ],
      },
      {
        name: "companies",
        title: "excursion.filter.companies",
        type: ListFilterConfigItemType.MultiSelect,
        options: companyOptions,
      },
      {
        name: "date",
        title: "excursion.filter.date",
        type: ListFilterConfigItemType.DateRange,
      },
    ],
    [cities, companyOptions, t]
  );

  const { renderDeleteBadges, renderSearch, renderFilterAccordion } = useListFilter({
    config,
    value,
    onChange: setValue as any,
  });

  return { value, renderSearch, renderDeleteBadges, renderFilterAccordion };
};

export default useExcursionFilter;
