import axios, { AxiosResponse } from "axios";
import queryString from "query-string";
import { Shadowing, ShadowingWithApplications } from "../../features/shadowings/types";
import {
  Company,
  CompanyBasic,
  CompanyPositionsFilter,
  CompanyProfileModel,
  CompanyType,
} from "../../features/companies/types";
import { User, UserForm } from "../../features/user/types";
import { localStorageHelpers } from "../helpers/localStorageHelpers";
import { School, SchoolCreateFormModel, SchoolEditFormModel, SchoolProfileModel } from "../../features/schools/types";
import { Municipality, MunicipalityProfileModel } from "../../features/municipalities/types";
import { Student, Grade, StudentRegistrationModel } from "../../features/students/types";
import {
  Application,
  IApplicationForm,
  ApplicationFilter,
  SeparatedApplications,
} from "../../features/applications/types";
import { Industry } from "../../features/industries/types";
import { ActiveShadowingFilter } from "../store/types/shadowingFilter";
import { Paginated } from "../../components/pagination/types";
import { ShadowingCreateRequestPayload, ShadowingUpdateRequestPayload } from "../../features/shadowing/types";
import { City } from "../hooks/useCities";
import { BusinessRegistrationModel } from "../../features/businessRegistration/types";
import { getISODate } from "../helpers/dateHelpers";
import { ReflectionFormModel, ReflectionReportModel } from "../../features/reflections/models";
import {
  ReportFilterResponse,
  ReportsCompanyStatsResponse,
  ReportsMunicipalityStatsResponse,
  ReportsSchoolStatsResponse,
} from "../../features/reports/models";
import { CompetenciesModel } from "../../components/competencies/models";
import { SeasonInfo } from "../../features/seasons/models";
import { SiteUser } from "../../features/users/types";
import { UserRoles } from "../auth/roles";
import {
  SchoolRegistrationContactStepFormModel,
  SchoolRegistrationModel,
} from "../../features/schoolRegistration/types";
import {
  Excursion,
  ExcursionCreateRequestPayload,
  ExcursionUpdateRequestPayload,
} from "../../features/excursions/types";
import { ExcursionFilter } from "../../features/excursions/useExcursionFilter";

const getAuthorizationHeader = () => {
  const token = localStorageHelpers.getUserToken();

  if (token) {
    return `Bearer ${token}`;
  }

  return null;
};

const getConfig = () => ({
  headers: {
    Authorization: getAuthorizationHeader(),
  },
});

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url, getConfig()).then(responseBody),
  post: <T>(url: string, body: any) => axios.post<T>(url, body, getConfig()).then(responseBody),
  put: <T>(url: string, body: any) => axios.put<T>(url, body, getConfig()).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url, getConfig()).then(responseBody),
};

const Shadowings = {
  list: (filter: ActiveShadowingFilter) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { dateRange, isOnline, ...rest } = filter;
    const params: Record<string, any> = rest;

    if (dateRange) {
      params.dateFrom = getISODate(dateRange.start);
      params.dateTo = getISODate(dateRange.end);
    }

    if (isOnline) {
      params.online = true;
    }

    return requests.get<Paginated<ShadowingWithApplications>>(`/shadowings?${queryString.stringify(params)}`);
  },
  listForCompany: (companyId: number, filter?: CompanyPositionsFilter) => {
    const filterParams = filter ? `?${queryString.stringify(filter)}` : "";

    return requests.get<ShadowingWithApplications[]>(`/shadowings/companies/${companyId}${filterParams}`);
  },
  listMyShadowings: (filter?: CompanyPositionsFilter) => {
    const filterParams = filter ? `?${queryString.stringify(filter)}` : "";

    return requests.get<ShadowingWithApplications[]>(`/shadowings/companies/me${filterParams}`);
  },
  details: (id: number) => requests.get<Shadowing>(`/shadowings/${id}`),
  createObsolete: (shadowing: Shadowing) => requests.post<number>("/shadowings", shadowing),
  create: (payload: ShadowingCreateRequestPayload) => requests.post<number>("/shadowings", payload),
  updateLegacy: (shadowing: Shadowing) => requests.put<void>(`/shadowings/${shadowing.id}`, shadowing),
  update: (id: number, payload: ShadowingUpdateRequestPayload) => requests.put<void>(`/shadowings/${id}`, payload),
  delete: (id: number) => requests.del<void>(`/shadowings/${id}`),
  cancel: (id: number) => requests.post<void>(`/shadowings/${id}/cancel`, {}),
  getApplications: (shadowingId: number) => requests.get<Application[]>(`/shadowings/${shadowingId}/applications`),
};

const Companies = {
  create: (company: Company) => requests.post<number>("/companies", company),
  update: (id: number, data: FormData) => {
    const contentType = { "Content-Type": "multipart/form-data" };
    const defaultConfig = getConfig();
    const config = { ...defaultConfig, headers: { ...defaultConfig.headers, ...contentType } };

    return axios.put<CompanyProfileModel>(`/companies/${id}`, data, config).then(responseBody);
  },
  delete: (id: number) => requests.del<void>(`/companies/${id}`),
  list: () => requests.get<CompanyBasic[]>("/companies/searchable"),
  listProfiles: (filter?: {
    municipalityIds?: number[];
    companyTypes?: string[];
    industryIds?: number[];
    search?: string;
  }) => {
    const filterParams = filter ? `?${queryString.stringify(filter)}` : "";

    return requests.get<CompanyProfileModel[]>(`/companies${filterParams}`);
  },
  listPendingRegistrations: () => {
    return requests.get<CompanyProfileModel[]>("/companies/pendingregistrations");
  },
  details: (id: number) => requests.get<Company>(`/companies/${id}`),
  getProfile: () => requests.get<CompanyProfileModel>("/companies/profile"),
  types: () => requests.get<CompanyType[]>("/companytypes"),
  approveRegistration: (id: number) => requests.put<void>(`companies/${id}/approve`, {}),
  rejectRegistration: (id: number, reason: string) => requests.put<void>(`companies/${id}/reject`, { reason }),
  register: (model: BusinessRegistrationModel) => {
    const formData = new FormData();
    Object.keys(model)
      .filter((key) => !["logo", "contactInfo"].includes(key))
      .forEach((key) => {
        formData.append(key, String(model[key as keyof BusinessRegistrationModel]));
      });

    if (model.logo instanceof File) {
      formData.append("logo", model.logo);
    }

    if (model.contactInfo) {
      Object.keys(model.contactInfo).forEach((key) => {
        formData.append(
          "contactInfo[" + key + "]",
          String(model.contactInfo[key as keyof BusinessRegistrationModel["contactInfo"]])
        );
      });
    }

    const contentType = { "Content-Type": "multipart/form-data" };
    const defaultConfig = getConfig();
    const config = { ...defaultConfig, headers: { ...defaultConfig.headers, ...contentType } };

    return axios.post<void>("/companies/register", formData, config).then(responseBody);
  },
};

const Users = {
  current: (): Promise<User> => requests.get<User>("/users"),
  login: (user: UserForm) => requests.post<User>("/users/login", user),
  logout: (): Promise<void> => requests.post<void>("/users/logout", {}),
  changePassword: (currentPassword: string, newPassword: string) => {
    return requests.post<void>("/users/changePassword", {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  },
  passwordReset: (email: string) => requests.post<void>("/users/resetpassword-request", { email: email }),
  passwordResetConfirm: (email: string, newPassword: string, token: string) => {
    return requests.post<void>("/users/resetpassword-confirmation", {
      email: email,
      newPassword: newPassword,
      token: token,
    });
  },
  list: (filter?: { roles?: UserRoles[]; search?: string; activePage?: number }) => {
    const filterParams = filter ? `?${queryString.stringify(filter!)}` : "";
    return requests.get<Paginated<SiteUser>>(`/admin/users${filterParams}`);
  },
  update: (id: number, email: string) => requests.put<void>("/users", { id: id, email: email }),
};

const Schools = {
  list: (filter?: { isVirtual?: boolean; search?: string; hadTraining?: string[] }) => {
    const filterParams = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<School[]>(`/schools${filterParams}`);
  },
  listMunicipality: (id: number | string) => {
    return requests.get<School[]>(`/schools/municipalities/${id}`);
  },
  delete: (id: number) => requests.del<void>(`/schools/${id}`),
  create: (school: SchoolCreateFormModel) => requests.post<number>("/schools", school),
  listGrades: () => requests.get<Grade[]>("/schools/grades"),
  getProfile: () => requests.get<SchoolProfileModel>("/schools/profile"),
  updateProfile: (id: number, data: SchoolProfileModel) => {
    return requests.put<SchoolProfileModel>(`/schools/${id}`, data);
  },
  update: (id: number, data: SchoolEditFormModel) => {
    return requests.put<SchoolProfileModel>(`/schools/${id}`, data);
  },
  register: (model: SchoolRegistrationModel) => requests.post<void>("/schools/onboardexistingschool", model),
  listPendingRegistrations: () =>
    requests.get<SchoolRegistrationContactStepFormModel[]>("/schools/pendingregistrations"),
  approveRegistration: (schoolId: string) => requests.put<void>(`/schools/${schoolId}/approve`, {}),
  rejectRegistration: (schoolId: string) => requests.put<void>(`/schools/${schoolId}/reject`, {}),
};

const Municipalities = {
  toggleActive: (id: number) => requests.put<void>(`/municipalities/${id}`, {}),
  list: () => requests.get<Municipality[]>("/municipalities"),
  options: () => requests.get<string[]>("/municipalities/options"),
  create: (municipality: Municipality) => requests.post<number>("/municipalities", municipality),
  getProfile: () => requests.get<MunicipalityProfileModel>("/municipalities/profile"),
  update: (id: number, data: MunicipalityProfileModel) => {
    return requests.put<MunicipalityProfileModel>(`/municipalities/${id}`, data);
  },
};

const Students = {
  list: (filter?: {
    hasApplications?: string[];
    grade?: string[];
    onlyDeactivated?: string[];
    name?: string;
    registrationYear?: number[];
    applicationYear?: number[];
  }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<Student[]>(`/students` + params);
  },
  details: (id: number) => requests.get<Student>(`/students/${id}`),
  update: (id: number, grade: number, phoneNumber: string) =>
    requests.put<Student>(`/students/${id}`, { grade: grade, phoneNumber: phoneNumber }),
  deactivate: (id: number) => requests.put<Student>(`/students/${id}/deactivate`, {}),
  activate: (id: number) => requests.put<Student>(`/students/${id}/activate`, {}),
  register: (model: StudentRegistrationModel) => requests.post<void>("/students/register", model),
  getProfile: () => requests.get<Student>("/students/profile"),
  listForShadowing: (shadowingId: number) => requests.get<Student[]>(`/shadowings/${shadowingId}/students`),
  listPendingRegistrations: () => requests.get<Student[]>("/students/pendingregistrations"),
  approveRegistration: (id: number) => requests.put<void>(`students/${id}/approve`, {}),
  rejectRegistration: (id: number) => requests.put<void>(`students/${id}/reject`, {}),
};

const Admin = {
  getProfile: () => requests.get<User>("/admin/profile"),
  impersonate: (email: string) => requests.post<User>("/admin/impersonate", { email }),
  confirmEmail: (user: SiteUser) => requests.post<void>(`/admin/users/${user.id}/confirm-email`, {}),
};

const Applications = {
  create: (application: IApplicationForm) => {
    const formData = new FormData();
    if (application.cv) {
      formData.append("cv", application.cv);
    }

    formData.append("shadowingId", application.shadowingId.toString());
    formData.append("motivationLetter", application.motivationLetter);
    formData.append("linkedinUrl", application.linkedinUrl);

    const contentType = { "Content-Type": "multipart/form-data" };
    const defaultConfig = getConfig();
    const config = { ...defaultConfig, headers: { ...defaultConfig.headers, ...contentType } };

    return axios.post<number>("/applications", formData, config).then(responseBody);
  },
  myApplications: () => requests.get<SeparatedApplications>("/applications/me"),
  list: (studentId: string, filter?: ApplicationFilter) => {
    const filterParams = filter ? `?${queryString.stringify(filter)}` : "";

    return requests.get<SeparatedApplications>(`/applications/students/${studentId}${filterParams}`);
  },
  approve: (applicationId: number, approved: boolean, reason?: string) => {
    const body = { status: approved ? 0 : 1, statusReason: reason };
    return requests.post<void>(`/applications/${applicationId}/confirmation`, body);
  },
  cancel: (id: number) => requests.post<void>(`/applications/${id}/cancel`, {}),
  cancelBySchool: (shadowingId: number, studentId: number) =>
    requests.post<void>(`/applications/shadowings/${shadowingId}/students/${studentId}/cancel`, {}),
};

const ApplicationReflections = {
  canFillReflection: (applicationId: number) =>
    requests.get<boolean>(`/applications/${applicationId}/reflections/canFill`),
  canFillFeedback: (applicationId: number) => requests.get<boolean>(`/applications/${applicationId}/feedback/canFill`),
  create: (applicationId: number, model: ReflectionFormModel) =>
    requests.post<void>(`/applications/${applicationId}/reflections`, model),
  get: (applicationId: number) => requests.get<Record<number, string>>(`/applications/${applicationId}/reflections`),
  getAll: (year: number) => {
    return requests.get<Record<string, Record<number, string>>>(`/applications/reflections?year=${year}`);
  },
  createInitial: (model: ReflectionFormModel) => requests.post<void>("/reflections", model),
  getInitial: () => requests.get<Record<number, string>>("/reflections"),
  getTextQuestions: (year: number) => {
    return requests.get<Record<string, Record<number, string>>>(`/reflections/textQuestions?year=${year}`);
  },
  writeFeedbackAboutStudent: (applicationId: number, model: ReflectionFormModel) =>
    requests.post<void>(`/reflections/${applicationId}/feedback`, model),
  writeCompanyFeedback: (model: ReflectionFormModel) => requests.post<void>(`/companyFeedback`, model),
  getCompanyFeedback: () => requests.get<ReflectionReportModel | null>(`/companyFeedback`),
};

const Files = {
  getCv: (id: string) => axios.get<Blob>(`/files/cvs/${id}`, { ...getConfig(), responseType: "blob" }),
};

const Industries = {
  list: () => requests.get<Industry[]>("/industries"),
};

const Cities = {
  list: () => requests.get<City[]>("/cities"),
};

const ExcursionCities = {
  list: () => requests.get<City[]>("/excursionCities"),
};

const Reports = {
  filters: () => {
    return requests.get<ReportFilterResponse>("/reports/filterOptions");
  },
  companyStats: (filter?: { gender?: string[]; grade?: string[]; years?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReportsCompanyStatsResponse>("/reports/company/stats" + params);
  },
  companyReflections: (filter?: { years?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/company/reflections" + params);
  },
  companyFeedback: (filter?: { years?: number[]; grade?: string[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/company/feedback" + params);
  },
  schoolStats: (filter?: { years?: number[]; grade?: string[]; gender?: string[]; companyIds?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReportsSchoolStatsResponse>("/reports/school/stats" + params);
  },
  schoolReflections: (filter?: { years?: number[]; companyIds?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/school/reflections" + params);
  },
  schoolFeedback: (filter?: { years?: number[]; companyIds?: number[]; grade?: string[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/school/feedback" + params);
  },
  schoolCompetencies: (filter?: { years?: number[]; grade?: string[]; gender?: string[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<CompetenciesModel>("/reports/school/competencies" + params);
  },
  municipalityReflections: (filter?: { years?: number[]; companyIds?: number[]; schoolIds?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/municipality/reflections" + params);
  },
  municipalityFeedback: (filter?: {
    years?: number[];
    companyIds?: number[];
    schoolIds?: number[];
    grade?: string[];
  }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/municipality/feedback" + params);
  },
  municipalityStats: (filter?: {
    years?: number[];
    grade?: string[];
    gender?: string[];
    companyIds?: number[];
    schoolIds?: number[];
  }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReportsMunicipalityStatsResponse>("/reports/municipality/stats" + params);
  },
  municipalityCompetencies: (filter?: {
    years?: number[];
    grade?: string[];
    gender?: string[];
    schoolIds?: number[];
  }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<CompetenciesModel>("/reports/municipality/competencies" + params);
  },
  adminReflections: (filter?: { years?: number[]; companyIds?: number[]; schoolIds?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/admin/reflections" + params);
  },
  adminFeedback: (filter?: { years?: number[]; companyIds?: number[]; schoolIds?: number[]; grade?: string[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReflectionReportModel>("/reports/admin/feedback" + params);
  },
  adminStats: (filter?: {
    years?: number[];
    grade?: string[];
    gender?: string[];
    companyIds?: number[];
    schoolIds?: number[];
  }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<ReportsMunicipalityStatsResponse>("/reports/admin/stats" + params);
  },
  adminCompetencies: (filter?: { years?: number[]; grade?: string[]; gender?: string[]; schoolIds?: number[] }) => {
    const params = filter ? `?${queryString.stringify(filter)}` : "";
    return requests.get<CompetenciesModel>("/reports/admin/competencies" + params);
  },
};

const Seasons = {
  list: () => requests.get<SeasonInfo>(`/seasons/info`),
  startSeason: () => requests.post<void>(`/seasons/start-season`, {}),
  stopSeason: () => requests.post<void>(`/seasons/stop-season`, {}),
  startRound: () => requests.post<void>(`/seasons/start-round`, {}),
  stopRound: () => requests.post<void>(`/seasons/stop-round`, {}),
};

const Excursions = {
  list: (filter: ExcursionFilter, sortBy: string, page: number) => {
    const params = {
      search: filter.search || undefined,
      cities: filter.cities.map((c) => c.value),
      online: filter.online.map((o) => o.value),
      companyIds: filter.companies.map((o) => o.value),
      dateFrom: filter.date?.start,
      dateTo: filter.date?.start,
      sortBy,
      activePage: page,
    };
    return requests.get<Paginated<Excursion>>(`/excursions?${queryString.stringify(params)}`);
  },
  listMyExcursions: (filter: { past?: boolean } = {}, page = 1) => {
    const params = {
      past: filter.past,
      activePage: page,
    };

    return requests.get<Paginated<Excursion>>(`/excursions/companies/me?${queryString.stringify(params)}`);
  },
  create: (payload: ExcursionCreateRequestPayload) => requests.post<number>("/excursions", payload),
  update: (id: number, payload: ExcursionUpdateRequestPayload) => requests.put<void>(`/excursions/${id}`, payload),
  delete: (id: number) => requests.post<void>(`/excursions/${id}/cancel`, {}),
};

const agent = {
  Shadowings,
  Companies,
  Users,
  Schools,
  Municipalities,
  Students,
  Admin,
  Applications,
  ApplicationReflections,
  Industries,
  Cities,
  ExcursionCities,
  Files,
  Reports,
  Seasons,
  Excursions,
};

export default agent;
