export enum BusinessRegistrationStep {
  Company,
  CompanyContacts,
  ContactInfo,
  Description,
  Password,
  Success,
}

export interface BusinessRegistrationCompanyStepFormModel {
  companyType: string;
  name: string;
  code: string;
  industryId: number;
  website: string;
  logo: string | File;
}

export interface BusinessRegistrationCompanyContactsStepFormModel {
  city: string;
  postcode: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface BusinessRegistrationContactInfoStepFormModel {
  contactInfo: {
    fullName: string;
    phoneNumber: string;
    email: string;
    jobTitle: string;
  };
}

export interface BusinessRegistrationDescriptionStepFormModel {
  description: string;
}

export interface BusinessRegistrationPasswordStepFormModel {
  password: string;
}

export interface BusinessRegistrationModel
  extends BusinessRegistrationCompanyStepFormModel,
    BusinessRegistrationCompanyContactsStepFormModel,
    BusinessRegistrationContactInfoStepFormModel,
    BusinessRegistrationDescriptionStepFormModel,
    BusinessRegistrationPasswordStepFormModel {}
