import React from "react";
import "./styles.less";

const PartnersArticle1 = () => {
  return (
    <article className="partners-block">
      <div className="article">
        <h2 className="article__title-sm">
          Transporto sektoriuje – didžiulis inžinierių poreikis: siūloma dešimtys darbo vietų ir imamasi priemonių
          sudominti vangiai inžineriją besirenkančius studentus
        </h2>
        <p>
          Transporto sektoriaus ekspertai kelerius pastaruosius metus pastebi augantį kvalifikuotų inžinerinių krypčių
          specialistų trūkumą. Palyginimui, 2017 m. iš 24 tūkst. bakalauro, kolegijų ir vientisųjų studijų pirmakursių
          transporto inžinerines kryptis rinkosi 1522 (6,3 proc.), o 2023 m. rudenį tokių studentų buvo likę perpus
          mažiau – 762 (3,2 proc.) iš daugiau nei 23 tūkst. pirmakursių. Modeliuojama, kad per artimiausią dešimtmetį
          transporto, elektronikos, aeronautikos, jūrų, statybos inžinerijos specialybių paklausa aplenks informacinių
          technologijų poreikį.
        </p>
        <p>
          Verslui ir viešajam sektoriui reikia kvalifikuotų specialistų, galinčių projektuoti, kurti ir prižiūrėti
          transporto sistemas: nuo kelių ar tiltų inžinierių iki elektromobilių įkrovimo stotelių, realių duomenų
          pateikimo vartotojams sistemų, transporto spūsčių valdymo sistemų valdytojų ir t. t. Aukšto lygio specialistų
          poreikis prognozuojamas be išimties visame Lietuvos susisiekimo sektoriuje, t. y. kelių, geležinkelių, vandens
          transporto, aviacijos ir ryšių srityse.
        </p>
        <p>
          „Susisiekimo karjeros laboratorijos“ iniciatyva siekiama, kad 2025 m. inžinerines transporto profesijas
          besirenkančių pirmakursių procentas nuo bendro stojančiųjų skaičiaus augtų ir sudarytų 3,6 proc., o 2030 m.
          šis santykis siektų jau 6,5 proc. Tai ilgalaikė Susisiekimo ministerijos ir dešimties jai pavaldžių įstaigų
          iniciatyva, kuria siekiama didinti transporto sektoriaus profesijų patrauklumą.
        </p>
        <div className="partners-block__footer">
          <p>
            Daugiau informacijos apie Susisiekimo karjeros laboratoriją čia:&nbsp;
            <a
              href="https://sumin.lrv.lt/lt/veiklos-sritys/kita-veikla/susisiekimo-karjeros-laboratorija/"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://sumin.lrv.lt/lt/veiklos-sritys/kita-veikla/susisiekimo-karjeros-laboratorija/
            </a>
          </p>
          <p>
            Daugiau skaitykite apie specialistų poreikį:&nbsp;
            <a
              href="https://sumin.lrv.lt/lt/naujienos/transporto-sektoriuje-didziulis-inzinieriu-poreikis-siuloma-desimtys-darbo-vietu-ir-imamasi-priemoniu-sudominti-vangiai-inzinerija-besirenkancius-studentus/"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://sumin.lrv.lt/lt/naujienos/transporto-sektoriuje-didziulis-inzinieriu-poreikis-siuloma-desimtys-darbo-vietu-ir-imamasi-priemoniu-sudominti-vangiai-inzinerija-besirenkancius-studentus/
            </a>
          </p>
        </div>
      </div>
    </article>
  );
};

export default PartnersArticle1;
