import React, { PropsWithChildren } from "react";
import { Message } from "semantic-ui-react";
import ShIcon from "./ShIcon";

interface Props {
  type: "warning";
}

const Alert = ({ children }: PropsWithChildren<Props>) => {
  return (
    <Message warning>
      <ShIcon name="warning" />
      {children}
    </Message>
  );
};

export default Alert;
