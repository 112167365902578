import React from "react";
import "./styles.less";

const PartnersArticle2 = () => {
  return (
    <article className="partners-block">
      <div className="article">
        <h2 className="article__title-sm">
          „Lietuvos Junior Achievement“ ir Susisiekimo ministerija kviečia moksleivius savaitei sėsti į transporto
          inžinierių kėdes
        </h2>
        <p>
          <strong>
            Susisiekimo ministerija kartu su penkiomis pavaldžiomis įstaigomis ir „Lietuvos Junior Achievement“ pristato
            „Šešėliavimo savaitę“ - jos metu daugiau nei 50 moksleivių bus suteikta galimybė praktikuotis transporto
            sektoriaus įstaigose ir praktiškai išbandyti įvairiausias specialybes, daugiau sužinoti apie karjeros
            galimybes. Iniciatyva vyks gegužės 27–31 dienomis.
          </strong>
        </p>

        <p>
          Lietuvos transporto sektorius jau dabar pradeda jausti specialistų trūkumą – praėjusių metų rudenį iš visų
          įstojusių tik 3,2 proc. pasirinko transporto inžinerinės krypties studijas. Aukšto lygio specialistų poreikis
          prognozuojamas be išimties visame Lietuvos susisiekimo sektoriuje, t. y. kelių, geležinkelių, vandens
          transporto, aviacijos ir ryšių srityse. Technologijoms vystantis, pavyzdžiui, augant elektromobilių skaičiui
          keliuose, kyla tokių specialistų, kaip elektromobilių stotelių inžinierių, poreikis ir pan.
        </p>

        <p>
          Todėl Susisiekimo ministerija suteikia unikalią progą praktiškai išbandyti, kaip atrodo šiuolaikinių
          transporto inžinierių darbo diena. „Šešėliavimo” savaitė, įgyvendinama kartu su organizacija „Lietuvos Junior
          Achievement“, leis daugiau nei 50 moksleivių iš visos Lietuvos įgyti praktikos bei patirties transporto
          sektoriuje, pritaikant teorines žinias praktikoje.
        </p>

        <p>
          „Ši iniciatyva suteikia nepakartojamą galimybę moksleiviams ne tik praktiškai susipažinti su darbu transporto
          srityje, bet ir iš pirmų lūpų išgirsti ir pamatyti, kaip kuriama susisiekimo ateitis – nuo netaršių transporto
          priemonių infrastruktūros plėtros iki pažangiausių technologinių ir inžinerinių sprendimų, leidžiančių mums
          visiems judėti greičiau, patogiau ir saugiau. Mūsų tikslas – parodyti jaunuoliams, kad karjera šiame
          sektoriuje ne tik perspektyvi, bet ir įdomi“, – sako susisiekimo viceministrė Agnė Vaiciukevičiūtė.
        </p>

        <p>
          „Šešėliavimo” programa susideda iš keturių dalių: įvadiniai užsiėmimai ir baigiamasis užsiėmimas vyksta
          klasėse, o „šešėliavimo“ vizitas – moksleivio pasirinktoje organizacijoje.{" "}
        </p>

        <p>
          „LJA Šešėliavimas“ ne tik suteiks moksleiviams praktinės patirties, bet ir skatins jų karjeros siekius bei
          motyvaciją. Tai puikus pavyzdys, kaip institucinis bendradarbiavimas gali lemti ne tik moksleivių ugdymą, bet
          ir transporto sektoriaus ateitį. Džiaugiamės prasminga partneryste su Susisiekimo ministerija, jos kontekste
          padėsime jaunuoliams iš naujo atrasti transporto sritį, galbūt – savo svajonių profesiją“, – teigia „Lietuvos
          Junior Achievement“ veiklos vystymo vadovė Akvilė Alauskaitė.{" "}
        </p>

        <p>
          Ši iniciatyva – Susisiekimo ministerijos projekto #SusisiekimoKarjerosLaboratorija dalis. Juo siekiama didinti
          transporto sektoriaus profesijų patrauklumą.{" "}
        </p>

        <div className="partners-block__footer">
          <p>
            Daugiau informacijos rasite čia:&nbsp;
            <a href="https://bit.ly/SKLveiklos " rel="noopener noreferrer" target="_blank">
              https://bit.ly/SKLveiklos
            </a>
          </p>
        </div>
      </div>
    </article>
  );
};

export default PartnersArticle2;
