import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";
import { DropdownItemProps, Form, Loader, Select } from "semantic-ui-react";
import { Excursion } from "./types";

interface Props {
  isDisabled?: boolean;
  onChange: (value: Excursion) => void;
}

const DuplicateExcursionDropdown = ({ isDisabled, onChange }: Props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Array<DropdownItemProps & { excursion: Excursion }> | undefined>(undefined);

  useEffect(() => {
    agent.Excursions.listMyExcursions().then((response) => {
      setOptions(
        response.items.map((excursion) => ({
          key: excursion.id,
          value: excursion.id,
          text: excursion.title,
          excursion,
        }))
      );
    });
  }, []);

  const isLoading = options == null;

  const handleChange = (id: number) => {
    const excursion = options?.find((option) => option.value === id)?.excursion;
    onChange(excursion!);
  };

  return (
    <Form.Field>
      <Select
        icon={isLoading ? <Loader active /> : undefined}
        disabled={isDisabled}
        options={options ?? []}
        search
        value={""}
        placeholder={t("excursion.form.copy_data")}
        onChange={(_, data) => handleChange(data.value as number)}
        selectOnBlur={false}
      />
    </Form.Field>
  );
};

export default DuplicateExcursionDropdown;
