import React from "react";
import PageTitle from "../../../components/page-title/PageTitle";
import { HasRole } from "../../../app/helpers/userHelpers";
import { UserRoles } from "../../../app/auth/roles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import { pluralize } from "../../../app/helpers/pluralize";

const ShadowingPageTitle = () => {
  const { t } = useTranslation();
  const count = useSelector((state: RootState) => state.shadowings.pagination?.totalCount);

  let description = "";
  let extra = "";

  if (HasRole(UserRoles.student)) {
    description = "home_page.description_student";
    extra = "home_page.extra_student";
  }

  if (HasRole(UserRoles.company)) {
    description = "home_page.description_company";
    extra = "home_page.extra_company";
  }

  if (HasRole(UserRoles.school)) {
    description = "home_page.description_school";
    extra = "home_page.extra_school";
  }

  if (HasRole(UserRoles.municipality)) description = "home_page.description_municipality";
  if (HasRole(UserRoles.admin)) description = "home_page.description_admin";

  // TODO: we can't rely on count here as we start to display old positions as well.
  // if (count) {
  //   return (
  //     <PageTitle
  //       titleHighlight={String(count)}
  //       title={t(pluralize("home_page.title", count))}
  //       description={t(description)}
  //       extra={t(extra)}
  //     />
  //   );
  // }

  return <PageTitle title={t("home_page.title")} description={t(description)} extra={t(extra)} />;
};

export default ShadowingPageTitle;
