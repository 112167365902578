import React from "react";
import { Item } from "semantic-ui-react";
import { ShadowingWithApplications } from "../types";
import { useTranslation } from "react-i18next";
import company from "../../../icons/company.svg";
import location from "../../../icons/location.svg";
import person from "../../../icons/person.svg";
import date from "../../../icons/date.svg";
import time from "../../../icons/time.svg";
import { prettifyDate, prettifyDuration } from "../../../app/helpers/dateHelpers";
import { Link } from "react-router-dom";
import ShadowingItemActions from "./ShadowingItemActions";
import agent from "../../../app/api/agent";
import useToaster from "../../../app/hooks/useToaster";
import { fetchShadowings } from "../actions";
import ShadowingCapacity from "./ShadowingCapacity";
import Multiline from "../../../components/Multiline";
import { HasRole } from "../../../app/helpers/userHelpers";
import { UserRoles } from "../../../app/auth/roles";
import ExcursionExplanationIcon from "../../shadowing/ExcursionExplanationIcon";
import { userActions } from "../../user/actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const NOW = new Date();

interface IProps {
  shadowing: ShadowingWithApplications;
  alreadyApplied?: boolean;
  showMoreCompanyPositions?: boolean;
  hideLogo?: boolean;
  openFeedbackModal?: boolean;
}

const ShadowingItem: React.FC<IProps> = ({
  shadowing,
  hideLogo,
  showMoreCompanyPositions = true,
  openFeedbackModal = false,
}) => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const dispatch = useAppDispatch();
  const isAdmin = HasRole(UserRoles.admin);
  const isInactive = new Date(shadowing.date) < NOW;

  const handleApplicationCancel = (applicationId: number) => {
    agent.Applications.cancel(applicationId)
      .then(() => {
        success(t("application.notifications.success.cancel"));
        dispatch(userActions.getCurrentUser());
        dispatch(fetchShadowings());
      })
      .catch(() => {
        apiError(t("common.errors.general"));
      });
  };

  const renderLocation = () => {
    return shadowing.online ? t("shadowing.remote") : `${shadowing.city}, ${shadowing.address}`;
  };

  return (
    <Item className={`shadowing-list-item ${isInactive ? "shadowing-list-item--inactive" : ""}`}>
      {!hideLogo && <Item.Image src={shadowing.company.logoUrl || "/assets/logo_placeholder.png"} alt="logo" />}
      <Item.Content>
        <Item.Header>{shadowing.position}</Item.Header>
        <Item.Meta>
          <span>
            <img src={location} alt="location" />
            {renderLocation()}
          </span>
          <span>
            <img src={company} alt="company" />
            {shadowing.company.name}
          </span>
          <span>
            <img src={person} alt="person" />
            <ShadowingCapacity shadowing={shadowing} />
          </span>
          <span>
            <img src={date} alt="date" />
            {prettifyDate(shadowing.date)}
          </span>
          {!!shadowing.visitDuration && (
            <span>
              <img src={time} alt="duration" />
              {t("shadowing.visit_duration")}: {prettifyDuration(shadowing.visitDuration)}
            </span>
          )}
          {!!shadowing.excursion && (
            <span>
              {t("common.excursion")} <ExcursionExplanationIcon />
            </span>
          )}
        </Item.Meta>
        <Item.Description>
          <Multiline value={shadowing.description} />
          {showMoreCompanyPositions && (
            <Link to={`/companies/${shadowing.company.id}`}>{t("common.more_company_positions")}</Link>
          )}
        </Item.Description>
        <Item.Extra className="shadowing-item-extra">
          <ShadowingItemActions
            shadowing={shadowing}
            handleApplicationCancel={handleApplicationCancel}
            openFeedbackModal={openFeedbackModal}
          />
          {isAdmin && (
            <div className="shadowing-item-extra__date">
              {t("shadowing.created_on")}
              {": "}
              <span>{prettifyDate(shadowing.createdOn)}</span>
            </div>
          )}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

export default ShadowingItem;
