import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { SORT_BY } from "../shadowingsFilter/sort_options";

interface Props {
  sortBy: SORT_BY;
  onSortChange: (value: SORT_BY) => void;

  text?: string;
  icon?: any;
}

const ExcursionSorter = ({ text, icon, onSortChange, sortBy }: Props) => {
  const { t } = useTranslation();

  const options = [
    {
      key: "nearest",
      text: <h4>{t("common.sort_by.nearest")}</h4>,
      value: SORT_BY.nearestFirst,
      content: t("common.sort_by.nearest"),
    },
    {
      key: "furthest",
      text: <h4>{t("common.sort_by.furthest")}</h4>,
      value: SORT_BY.furthestFirst,
      content: t("common.sort_by.furthest"),
    },
  ];

  const handleChange = (_: any, { value }: any) => {
    onSortChange(value);
  };

  return (
    <div className="shadowing-sorter">
      <Dropdown icon={icon} text={text} options={options} value={sortBy} onChange={handleChange} />
    </div>
  );
};

export default ExcursionSorter;
