import React from "react";
import PageTitle from "../../components/page-title/PageTitle";
import { useTranslation } from "react-i18next";
import "./styles.less";

const iniciators = [
  {
    title: "Lietuvos Respublikos susisiekimo ministerija",
    url: "https://sumin.lrv.lt/lt/",
    logo: "/assets/partners/lr-sm.png",
  },
  {
    title: "Susisiekimo karjeros laboratorija",
    url: "https://sumin.lrv.lt/lt/veiklos-sritys/kita-veikla/susisiekimo-karjeros-laboratorija/",
    logo: "/assets/partners/skl.png",
  },
];

const partners = [
  { title: "Lietuvos Geležinkeliai", url: "https://ltg.lt/", logo: "/assets/partners/ltg.png" },
  { title: "Viamatika", url: "https://viamatika.lt/", logo: "/assets/partners/viamatika.png" },
  { title: "Kelių priežiūra", url: "https://keliuprieziura.lt/", logo: "/assets/partners/keliu-prieziura.png" },
  { title: "Vidaus vandens kelių direkcija", url: "https://vvkd.lt/", logo: "/assets/partners/vvkd.png" },
  { title: "Via Lietuva", url: "https://vialietuva.lt/", logo: "/assets/partners/via-lietuva.png" },
];

const PartnersMainArticle = () => {
  const { t } = useTranslation();

  return (
    <article className="partners-main">
      <PageTitle
        title={t("navbar.partners")}
        description={`Visos Lietuvos Junior Achievement ugdymo programos moksleiviams yra kuriamos kartu su geranoriškais ir
            Lietuvos jaunimo ateitimi tikinčiais poveikio investuotojais ir partneriais. Tik jų dėka galime aktyviai
            ugdyti moksleivių savęs pažinimo bei ugdymo karjerai įgūdžius ir stiprias jų asmenybes, kurios jau greitai
            įsilies į Lietuvos verslo, pilietinį bei visuomeninį gyvenimą. LJA misija tiki šios organizacijos:`}
      />

      <h3>Iniciatyvos iniciatoriai</h3>

      <ul className="partners-main__logos">
        {iniciators.map((partner) => (
          <li key={partner.title}>
            <a href={partner.url} rel="noopener noreferrer" target="_blank" title={partner.title}>
              <img src={partner.logo} alt={partner.title} />
            </a>
          </li>
        ))}
      </ul>

      <h3>Partneriai</h3>

      <ul className="partners-main__logos">
        {partners.map((partner) => (
          <li key={partner.title}>
            <a href={partner.url} rel="noopener noreferrer" target="_blank" title={partner.title}>
              <img src={partner.logo} alt={partner.title} />
            </a>
          </li>
        ))}
      </ul>
    </article>
  );
};

export default PartnersMainArticle;
